import 'css/components/Room.css';
import Player from 'components/Player';
import Door from 'components/Door';

const Room = ({roomName, doorDetails, row, col, rowOffset, colOffset}) => {
  let doors = doorDetails.map((door) => 
    <Door
      room={door.roomName}
      row={door.row + rowOffset}
      col={door.col + colOffset}
      isReturnDoor={door.isReturnDoor}
    />
  );
  return (
    <div className="room">
      <div className="room-title">
        {roomName}
      </div>
      <Player row={row} col={col} />
      {doors}
    </div>
  );
}

export default Room;

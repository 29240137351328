import "index.css";
import React from "react";
import * as ReactDOMClient from 'react-dom/client'
import App from "App";
import reportWebVitals from "reportWebVitals";
import { BrowserRouter } from "react-router-dom";

// Check Mobile Function
window.mobileAndTabletCheck = function() {
  return /Android|iPhone/i.test(navigator.userAgent)
};
// Check Mobile Function - END

const container = document.getElementById("root")
const root = ReactDOMClient.createRoot(container);
root.render(
  <BrowserRouter>
    <App className="app"/>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

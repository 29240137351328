import 'css/components/Arrows.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown
} from '@fortawesome/free-solid-svg-icons';

const leftButtonPressedEvent = new CustomEvent("gui-button", {detail: "left"});
const rightButtonPressedEvent = new CustomEvent("gui-button", {detail: "right"});
const upButtonPressedEvent = new CustomEvent("gui-button", {detail: "up"});
const downButtonPressedEvent = new CustomEvent("gui-button", {detail: "down"});

const Arrows = () => {
  const leftButtonClick = () => {
    document.dispatchEvent(leftButtonPressedEvent);
  };
  const rightButtonClick = () => {
    document.dispatchEvent(rightButtonPressedEvent);
  };
  const upButtonClick = () => {
    document.dispatchEvent(upButtonPressedEvent);
  };
  const downButtonClick = () => {
    document.dispatchEvent(downButtonPressedEvent);
  };
  return (
    <div className="arrows">
      <button className="arrow" onClick={leftButtonClick}><FontAwesomeIcon icon={faArrowLeft} /></button>
      <div className="vertical">
        <button className="arrow-vertical" onClick={upButtonClick}><FontAwesomeIcon icon={faArrowUp} /></button>
        <button className="arrow-vertical" onClick={downButtonClick}><FontAwesomeIcon icon={faArrowDown} /></button>
      </div>
      <button className="arrow" onClick={rightButtonClick}><FontAwesomeIcon icon={faArrowRight} /></button>
    </div>
  );
}

export default Arrows;

import { BOX_DIM } from 'constants';
import { useState, useEffect } from 'react';

export const usePlayerMovement = ({roomName, setRoomName, doorDetails, roomPath, setRoomPath}) => {
  const maxRowOffset = window.mobileAndTabletCheck() ?
    Math.floor(0.2 * window.innerHeight / BOX_DIM) : 0;
  const maxRow = Math.floor(window.innerHeight / BOX_DIM) - 3 - maxRowOffset;
  const maxCol = Math.floor(window.innerWidth / BOX_DIM) - 3;
  const middleRow = Math.floor(maxRow / 2);
  const middleCol = Math.floor(maxCol / 2);
  const [rowOffset, setRowOffset] = useState(0);
  const [colOffset, setColOffset] = useState(0);
  const [row, setRow] = useState(middleRow);
  const [col, setCol] = useState(middleCol);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if(event.detail === 'right' || event.key === 'ArrowRight') {
        if (col === maxCol)
          setColOffset(colOffset => colOffset - 1);
        else
          setCol(col => Math.min(maxCol, col + 1));
      }
      else if(event.detail === 'left' || event.key === 'ArrowLeft') {
        if (col === 0)
          setColOffset(colOffset => colOffset + 1);
        else
          setCol(col => col - 1);
      }
      else if(event.detail === 'up' || event.key === 'ArrowUp') {
        if (row === 0)
          setRowOffset(rowOffset => rowOffset + 1);
        else
          setRow(row => row - 1);
      }
      else if(event.detail === 'down' || event.key === 'ArrowDown') {
        if (row === maxRow)
          setRowOffset(rowOffset => rowOffset - 1);
        else
          setRow(row => row + 1);
      }
    }
    // Reset Player position when entering door
    doorDetails.forEach((door) => {
      if (door.row + rowOffset === row && door.col + colOffset === col) {
        if (roomPath.length >= 1 && roomPath[roomPath.length - 1] === door.roomName) {
          setRoomPath(roomPath => roomPath.slice(0, -1));
        } else {
          setRoomPath(roomPath => roomPath.concat([roomName]));
        }
        setRoomName(door.roomName);
        setRow(middleRow);
        setCol(middleCol);
        setRowOffset(0);
        setColOffset(0);
      }
    });
    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('gui-button', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('gui-button', handleKeyPress);
    };
  }, [setRow, setCol, setRowOffset, setColOffset, roomName, setRoomName, doorDetails, roomPath, setRoomPath]);

  return [row, col, rowOffset, colOffset];
}

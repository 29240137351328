import 'css/components/Door.css';
import { BOX_DIM } from 'constants';

const Door = ({room, row, col, isReturnDoor}) => {
  let leftPosition = (col + 1) * BOX_DIM;
  let topPosition = (row + 2) * BOX_DIM;

  if (room === 'undefined') {
    return;
  }
  if (isReturnDoor) {
    return (
      <div 
        className="return door"
        style={{left: `${leftPosition}px`, top: `${topPosition}px`}}
      >
        {`back ${room}`}
      </div>
    );
  }
  return (
    <div 
      className="door"
      style={{left: `${leftPosition}px`, top: `${topPosition}px`}}
    >
      to {room}
    </div>
  );
}

export default Door;

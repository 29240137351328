import { BOX_DIM } from 'constants';
import { useState } from 'react';
import 'css/pages/GamePage.css';
import { Outlet } from "react-router-dom";
import Room from 'components/Room';
import Profile from 'components/Profile';
import Arrows from 'components/Arrows';
import Chance from 'chance';
import { usePlayerMovement } from 'hooks/PlayerMovement';

const GamePage = () => {
  var arrows = window.mobileAndTabletCheck() ? (<Arrows />) : null;
  const [roomName, setRoomName] = useState("home");
  const [roomPath, setRoomPath] = useState([]);
  const chance = new Chance(roomName + new Date().toLocaleDateString());
  // TODO make sure none of these collide with fixed positions
  let doorDetails = [
    {
      row: chance.integer({ min: 0, max: 13 }),
      col: chance.integer({ min: 0, max: 34 }),
      roomName: chance.string({ alpha: true, casing: 'lower', length: 4 }),
      isReturnDoor: false,
    },
    {
      row: chance.integer({ min: 0, max: 13 }),
      col: chance.integer({ min: 0, max: 34 }),
      roomName: chance.string({ alpha: true, casing: 'lower', length: 4 }),
      isReturnDoor: false,
    },
    {
      row: chance.integer({ min: 0, max: 13 }),
      col: chance.integer({ min: 0, max: 34 }),
      roomName: chance.string({ alpha: true, casing: 'lower', length: 4 }),
      isReturnDoor: false,
    },
    {
      row: chance.integer({ min: 0, max: 13 }),
      col: chance.integer({ min: 0, max: 34 }),
      roomName: chance.string({ alpha: true, casing: 'lower', length: 4 }),
      isReturnDoor: false,
    },
  ];
  const maxRowOffset = window.mobileAndTabletCheck() ?
    Math.floor(0.2 * window.innerHeight / BOX_DIM) : 0;
  const maxRow = Math.floor(window.innerHeight / BOX_DIM) - 3 - maxRowOffset;
  const maxCol = Math.floor(window.innerWidth / BOX_DIM) - 3;
  const middleRow = Math.floor(maxRow / 2) + 1;
  const middleCol = Math.floor(maxCol / 2);
  if (roomPath.length > 0) {
    doorDetails.push({
      row: middleRow,
      col: middleCol,
      roomName: roomPath[roomPath.length - 1],
      isReturnDoor: true,
    });
  }
  const [row, col, rowOffset, colOffset] = usePlayerMovement({
    roomName,
    setRoomName,
    doorDetails,
    roomPath,
    setRoomPath,
  });
  return (
    <>
      <Room
        roomName={roomName}
        doorDetails={doorDetails}
        row={row}
        col={col}
        rowOffset={rowOffset}
        colOffset={colOffset}
      />
      <Profile />
      {arrows}
      <Outlet/>
    </>
  );
}

export default GamePage;

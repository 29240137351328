import { useNavigate } from 'react-router-dom';
import { faker } from '@faker-js/faker';

const joinAsGuest = (navigate) => {
  let adjective = faker.word.adjective();
  let noun = faker.word.noun();
  localStorage.setItem("arkayde-io-guest-name", `${adjective}-${noun}`);
  navigate('/explore');
}

const GuestButton = () => {
  const navigate = useNavigate();
  return (
    <button
      className="guest-button"
      onClick={() => {joinAsGuest(navigate)}}
    >
      Continue As Guest
    </button>
  );
}

export default GuestButton;

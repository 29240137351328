import { useNavigate } from 'react-router-dom';

const EnterButton = () => {
  const navigate = useNavigate();
  return (
    <button
      className="guest-button"
      onClick={() => {navigate('/explore')}}
    >
      Continue
    </button>
  );
}

export default EnterButton;

import 'css/components/Player.css';
import { BOX_DIM } from 'constants';

const Player = (props) => {
  let leftPosition = (props.col + 1) * BOX_DIM;
  let topPosition = (props.row + 2) * BOX_DIM;
  return (
    <div
      className="main-char"
      style={{left: `${leftPosition}px`, top: `${topPosition}px`}}
    />
  );
}

export default Player;

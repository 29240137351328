import GamePage from "pages/GamePage";
import HomePage from "pages/HomePage";
import { Auth0Provider } from '@auth0/auth0-react';
import {
  Routes,
  Route
} from "react-router-dom";


const App = () => {
  return (
    <Auth0Provider
      domain="dev-w1x7cjt08u14t3nv.us.auth0.com"
      clientId="BfyyxdEeVjBh3BYAJTBZ2x22cXuIi9yF"
      authorizationParams={{
        redirect_uri: window.location.origin + "/explore"
      }}
    >
      <Routes key="routes">
        <Route path="/" element={(<HomePage />)} />
        <Route path="/explore" element={(<GamePage />)} />
      </Routes>
    </Auth0Provider>
  );
}

export default App;

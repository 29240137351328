import { useAuth0 } from "@auth0/auth0-react";
import { useGuest } from 'hooks/Guest';
import LoginButton from 'components/Login';
import GuestButton from 'components/GuestButton';
import LogoutButton from 'components/Logout';
import 'css/components/Profile.css';

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const guestName = useGuest();
  if (window.mobileAndTabletCheck()) {
    if (isAuthenticated) {
      return (
        <div className="profile">
          <LogoutButton />
        </div>
      );
    }
    return (
      <div className="profile">
        <LoginButton />
      </div>
    );
  }

  if (isLoading) {
    return <div className="profile">Loading ...</div>;
  }

  if (!isAuthenticated) {
    if (guestName) {
      return (
        <div className="profile">
          <div className="user-name">
            {guestName}
          </div>
          <LoginButton />
        </div>
      );
    }
    return (
      <div className="profile">
        <GuestButton />
        <LoginButton />
      </div>
    );
  }

  return (
    <div className="profile">
      <div className="user-name">
        {user.name}
      </div>
      <LogoutButton />
    </div>
  );
};

export default Profile;

import 'css/pages/HomePage.css';
import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Room from 'components/Room';
import LoginButton from 'components/Login';
import LogoutButton from 'components/Logout';
import GuestButton from 'components/GuestButton';
import EnterButton from 'components/EnterButton';
import Profile from 'components/Profile';
import { useAuth0 } from "@auth0/auth0-react";

const HomePage = (props) => {
  const { isAuthenticated, isLoading } = useAuth0();
  var body = (
    <div className="home-body">
      <LoginButton />
      <GuestButton />
    </div>
  );
  if (!isLoading && isAuthenticated) {
    body = (
      <div className="home-body">
        <Profile />
        <EnterButton />
      </div>
    );
  }
  return (
    <div className="home-page">
      <div id="title" className="title">Arkayde.io</div>
      {body}
      <div className="home-info">New rooms every 24 hrs</div>
      <Outlet/>
    </div>
  );
}

export default HomePage;